import * as React from 'react';
import { FC } from 'react';
import {
  Edit,
  EditProps,
  FormTab,
  NumberInput,
  required,
  TabbedForm,
  TextInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { CustomImageInput, JsonInput } from '../components/inputs';
import { CustomToolbar } from '../components/toolbars';

const useStyles = makeStyles({
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tab: {
    display: 'block',
  },
});

const TagEdit: FC<EditProps> = props => {
  const classes = useStyles();
  return (
    <Edit {...props} mutationMode={'pessimistic'}>
      <TabbedForm redirect="show" toolbar={<CustomToolbar saveable />}>
        <FormTab
          label="resources.tags.tabs.details"
          contentClassName={classes.tab}
        >
          <TextInput source="title" fullWidth validate={requiredValidate} />
          <CustomImageInput source="image" dirPath="images" />
          <TextInput
            source="shortDescription"
            multiline
            fullWidth
            validate={requiredValidate}
          />
          <TextInput
            multiline
            fullWidth
            source="description"
            label="Description"
            validate={requiredValidate}
          />
          <NumberInput source="priority" />
          <JsonInput source="metadata" />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const requiredValidate = [required()];

export default TagEdit;
