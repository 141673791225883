import * as React from 'react';
import { FC } from 'react';
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  DateField,
  EditButton,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { COLLECTION_LINK } from '../utils';
import { LinkField } from '../components/fields';
import LinkAside from './LinkAside';

const useStyles = makeStyles(theme => ({
  hiddenOnSmallScreens: {
    display: 'table-cell',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  image: {
    maxWidth: 120,
  },
}));

const LinkList: FC<ListProps> = props => {
  const classes = useStyles();
  // const { loaded, permissions } = usePermissions();
  console.log('applist: ', props);
  return (
    <List
      {...props}
      perPage={25}
      sort={{ field: 'updatedAt', order: 'DESC' }}
      bulkActionButtons={false}
      aside={<LinkAside />}
    >
      <Datagrid optimized rowClick="show">
        <LinkField label="id" source="id" reference={COLLECTION_LINK} />
        <TextField source="id" />
        <TextField source="source" />
        <TextField source="type" />
        <DateField source="updatedAt" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default LinkList;
