import InvoiceIcon from '@material-ui/icons/LibraryBooks';
import AppBuildCreate from './AppBuildCreate';
import AppBuildEdit from './AppBuildEdit';
import AppBuildList from './AppBuildList';
import AppBuildShow from './AppBuildShow';

export default {
  list: AppBuildList,
  show: AppBuildShow,
  create: AppBuildCreate,
  edit: AppBuildEdit,
  icon: InvoiceIcon,
};
