import React, { useState } from 'react';
import {
  Button,
  SaveButton,
  useCreate,
  useNotify,
  FormWithRedirect,
  SelectInput,
  NumberInput,
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {
  AgentNameOptions,
  AgentQueueNameOptions,
  JobNameOptions,
} from '../utils';
import { agentCreateJob } from '../authProvider/agentApi';

function AgentCreateJobButton({ onChange, record }: any) {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('posts');
  const notify = useNotify();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (data: any) => {
    console.log('create agent job data:', data);
    const response = await agentCreateJob(data);
    console.log('create agent job response:', response);
    if (response.status === 201) {
      setShowDialog(false);
      notify('Create job succeed', 'info');
    } else {
      notify(response?.body?.error?.message || 'Create job failed', 'error');
    }
  };

  return (
    <>
      <Button onClick={handleClick} label="Create agent job">
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create agent job"
      >
        <DialogTitle>Create agent job</DialogTitle>

        <FormWithRedirect
          resource="agents"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, saving }) => (
            <>
              <DialogContent>
                <SelectInput
                  source="agentName"
                  label="Agent name"
                  choices={AgentNameOptions}
                  fullWidth
                />
                <SelectInput
                  source="queueName"
                  label="Queue name"
                  choices={AgentQueueNameOptions}
                  fullWidth
                />
                <SelectInput
                  source="jobName"
                  label="Job name"
                  choices={JobNameOptions}
                  fullWidth
                />
                <NumberInput
                  source="data.resourceId"
                  label="Resource ID"
                  defaultValue={record.id}
                  fullWidth
                />
                <NumberInput source="options.delay" label="Delay" fullWidth />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

export default AgentCreateJobButton;
