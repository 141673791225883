import InvoiceIcon from '@material-ui/icons/LibraryBooks';
import AppLinkEdit from './AppLinkEdit';
import AppLinkList from './AppLinkList';
import AppLinkShow from './AppLinkShow';

export default {
  list: AppLinkList,
  show: AppLinkShow,
  // create: AppLinkCreate,
  edit: AppLinkEdit,
  icon: InvoiceIcon,
};
