import { includes, indexOf, sortBy } from 'lodash';
import { ImageData } from '../entities/ImageData';

export class ImageUtils {
  static parseImages(srcs?: string[], selectedIndexes?: number[]): ImageData[] {
    if (!srcs) {
      return [];
    }
    return sortBy(
      srcs.map((item: string, index: number) => {
        return {
          src: item,
          index,
          selected: includes(selectedIndexes, index),
        };
      }),
      [
        item => {
          const selectedIndex = indexOf(selectedIndexes, item.index);
          return selectedIndex < 0 ? 10000 : selectedIndex;
        },
      ]
    );
  }

  static parseVideo(youtubeId?: string): ImageData | undefined {
    if (!youtubeId) {
      return;
    }
    return {
      index: 0,
      selected: false,
      src: `https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`,
      youtubeId: youtubeId,
    };
  }

  static parseVideos(
    youtubeIds?: string[] | string,
    selectedYoutubeIds?: string[]
  ): ImageData[] {
    if (!youtubeIds) {
      return [];
    }
    if (!Array.isArray(youtubeIds)) {
      youtubeIds = [youtubeIds];
    }
    return youtubeIds.map((item: string, index: number) => {
      return {
        src: `https://img.youtube.com/vi/${item}/hqdefault.jpg`,
        index,
        selected: includes(selectedYoutubeIds, item),
        youtubeId: item,
      };
    });
  }

  static reorder = (array: any[], from: number, to: number) => {
    array = array.slice();
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
    return array;
  };
}
