import * as React from 'react';
import { FC } from 'react';
import {
  List,
  ListProps,
  Datagrid,
  DateField,
  NumberField,
  EditButton,
  Filter,
  TextInput,
} from 'react-admin';
import {
  COLLECTION_APP_BUILD,
  COLLECTION_APP_RESOURCE,
  COLLECTION_APP_VERSION,
} from '../utils';
import { LinkField } from '../components/fields';

const BuildFilter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput label="Id" source="id_eq" defaultValue="" alwaysOn />
      <TextInput label="Resource" source="appId_eq" defaultValue="" alwaysOn />
    </Filter>
  );
};

const AppBuildList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      perPage={25}
      sort={{ field: 'updatedAt', order: 'DESC' }}
      bulkActionButtons={false}
      filters={<BuildFilter />}
    >
      <Datagrid optimized rowClick="show">
        <LinkField label="id" source="id" reference={COLLECTION_APP_BUILD} />
        <LinkField
          label="Resource"
          source="appId"
          reference={COLLECTION_APP_RESOURCE}
        />
        <LinkField
          label="Version"
          source="versionId"
          reference={COLLECTION_APP_VERSION}
        />
        <NumberField source="number" />
        <DateField source="updatedAt" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default AppBuildList;
