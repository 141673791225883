import React, { useState } from 'react';
import {
  Button,
  SaveButton,
  useNotify,
  FormWithRedirect,
  useCreate,
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { appResourcePublishGooglePlay } from '../authProvider/appResourceApi';

function AppResourcePublishGooglePlayButton() {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('posts');
  const notify = useNotify();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (data: any) => {
    console.log('publish google play with data:', data);
    const response = await appResourcePublishGooglePlay(data);
    console.log('publish google play response:', response);
    if (response.status === 201) {
      setShowDialog(false);
      notify('App published on Google Play', 'info');
    } else {
      notify(
        response?.body?.error?.message || 'Failed to publish on Google Play',
        'error'
      );
    }
  };

  return (
    <>
      <Button onClick={handleClick} label="Publish on Google Play">
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Publish on Google Play"
      >
        <DialogTitle>Publish app</DialogTitle>

        <FormWithRedirect
          resource="image"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, saving }) => (
            <>
              <DialogContent>
                <DialogContentText>
                  Are you sure to publish this app?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  saving={saving}
                  disabled={loading}
                  label="Publish"
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

export default AppResourcePublishGooglePlayButton;
