import InvoiceIcon from '@material-ui/icons/LibraryBooks';
import AppResourceEdit from './AppResourceEdit';
import AppResourceList from './AppResourceList';
import AppResourceShow from './AppResourceShow';

export default {
  list: AppResourceList,
  show: AppResourceShow,
  edit: AppResourceEdit,
  icon: InvoiceIcon,
};
