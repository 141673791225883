import { FunctionComponent, useEffect, useState } from 'react';
import { useRecordContext } from 'react-admin';
import { get } from 'lodash';
import { ImageUtils } from '../utils/ImageUtils';
import { MyImageItem } from '../common/MyImageList';

export type VideoFieldProps = {
  label?: string;
  addLabel?: boolean;
  source: string;
};

export const VideoField: FunctionComponent<VideoFieldProps> = props => {
  const { source } = props;
  const record = useRecordContext(props);
  const [data, setData] = useState(ImageUtils.parseVideo(get(record, source)));

  useEffect(() => {
    setData(ImageUtils.parseVideo(get(record, source)));
  }, [record, source]);

  if (!data) {
    return null;
  }
  return <MyImageItem data={data} />;
};

VideoField.defaultProps = {
  addLabel: true,
};
