import * as React from 'react';
import { FC } from 'react';
import {
  Create,
  CreateProps,
  FormTab,
  NumberInput,
  required,
  TabbedForm,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import { CustomToolbar } from '../components/toolbars';
import CreateActions from '../components/actions/CreateActions';

export const styles = {
  price: { width: '7em' },
  width: { width: '7em' },
  height: { width: '7em' },
  stock: { width: '7em' },
  widthFormGroup: { display: 'inline-block' },
  heightFormGroup: { display: 'inline-block', marginLeft: 32 },
  dInlineBlock: {
    display: 'inline-block',
    width: '50%',
  },
};
const useStyles = makeStyles(styles);

const AppBinaryCreate: FC<CreateProps> = props => {
  const classes = useStyles();
  return (
    <Create {...props} actions={<CreateActions saveable />}>
      <TabbedForm
        redirect="show"
        toolbar={<CustomToolbar saveable alwaysEnableSaveButton={true} />}
      >
        <FormTab label="resources.apps.tabs.details">
          <NumberInput source="buildId" />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};
const requiredValidate = [required()];

export default AppBinaryCreate;
