import * as React from 'react';
import { FC } from 'react';
import {
  DateField,
  FormTab,
  TabbedForm,
  TextField,
  Show,
  ShowProps,
  NumberField,
  ImageField,
  RichTextField,
} from 'react-admin';
import { JsonField } from 'react-admin-json-view';
import { makeStyles } from '@material-ui/core/styles';
import { CustomToolbar } from '../components/toolbars';
import JsonFieldUtils from '../components/utils/JsonFieldUtils';

const useStyles = makeStyles({
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tab: {
    display: 'block',
  },
});

const TagShow: FC<ShowProps> = props => {
  const classes = useStyles();
  return (
    <Show {...props}>
      <TabbedForm redirect="show" toolbar={<CustomToolbar />}>
        <FormTab
          label="resources.tags.tabs.details"
          contentClassName={classes.tab}
        >
          <TextField source="id" />
          <TextField source="slug" />
          <TextField source="title" />
          <ImageField source="image" />
          <RichTextField source="shortDescription" />
          <RichTextField source="description" />
          <NumberField source="priority" />
          <JsonField {...JsonFieldUtils.generateProps('metadata')} />
          <DateField source="updatedAt" />
        </FormTab>
      </TabbedForm>
    </Show>
  );
};

export default TagShow;
