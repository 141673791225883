import * as React from 'react';
import { FC } from 'react';
import {
  DateField,
  FormTab,
  TabbedForm,
  TextField,
  Show,
  ShowProps,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {
  COLLECTION_APP,
  COLLECTION_APP_RESOURCE,
  COLLECTION_LINK,
} from '../utils';
import { LinkField } from '../components/fields/LinkField';
import { CustomToolbar } from '../components/toolbars';

const useStyles = makeStyles({
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tab: {
    display: 'block',
  },
  dInlineBlock: {
    wordBreak: 'break-word',
    display: 'inline-block',
    width: '50%',
  },
});

const AppLinkShow: FC<ShowProps> = props => {
  const classes = useStyles();
  return (
    <Show {...props}>
      <TabbedForm redirect="show" toolbar={<CustomToolbar />}>
        <FormTab
          label="resources.apps.tabs.details"
          contentClassName={classes.tab}
        >
          <TextField source="id" />
          <LinkField label="App" source="appId" reference={COLLECTION_APP} />
          <LinkField
            label="Resource"
            source="appId"
            reference={COLLECTION_APP_RESOURCE}
          />
          <LinkField
            label="Link id"
            source="linkId"
            reference={COLLECTION_LINK}
          />
          <TextField source="status" />
          <DateField source="updatedAt" />
        </FormTab>
      </TabbedForm>
    </Show>
  );
};

export default AppLinkShow;
