import * as React from 'react';
import { FC } from 'react';
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  DateField,
  Filter,
  EditButton,
  TextInput,
  SelectInput,
} from 'react-admin';
import {
  COLLECTION_APP_RESOURCE,
  COLLECTION_APP_SCREENSHOT,
  DeviceDisplayOptions,
  ScreenshotStatusOptions,
} from '../utils';
import { LinkField } from '../components/fields';

const AppScreenshotFilter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput label="Id" source="id_eq" defaultValue="" alwaysOn />
      <TextInput label="Resource" source="appId_eq" defaultValue="" alwaysOn />
      <SelectInput
        label="Device Display"
        source="deviceDisplay_eq"
        choices={[...DeviceDisplayOptions]}
        alwaysOn
      />
      <SelectInput
        label="Status"
        source="status_eq"
        choices={[...ScreenshotStatusOptions]}
        alwaysOn
      />
    </Filter>
  );
};

const AppScreenshotList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      perPage={25}
      sort={{ field: 'updatedAt', order: 'DESC' }}
      bulkActionButtons={false}
      filters={<AppScreenshotFilter />}
    >
      <Datagrid optimized rowClick="show">
        <LinkField
          label="id"
          source="id"
          reference={COLLECTION_APP_SCREENSHOT}
        />
        <LinkField
          label="Resource"
          source="appId"
          reference={COLLECTION_APP_RESOURCE}
        />
        <TextField source="deviceDisplay" />
        <TextField source="status" />
        <DateField source="updatedAt" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default AppScreenshotList;
