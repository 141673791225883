import * as React from 'react';
import { FC } from 'react';
import {
  Edit,
  EditProps,
  FormTab,
  required,
  SelectInput,
  TabbedForm,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { AppLinkStatusOptions } from '../utils';
import { CustomToolbar } from '../components/toolbars';

const useStyles = makeStyles({
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tab: {
    display: 'block',
  },
  dInlineBlock: {
    wordBreak: 'break-word',
    display: 'inline-block',
    width: '50%',
  },
});

const AppLinkEdit: FC<EditProps> = props => {
  const classes = useStyles();
  return (
    <Edit {...props} mutationMode={'pessimistic'}>
      <TabbedForm redirect="show" toolbar={<CustomToolbar saveable />}>
        <FormTab
          label="resources.apps.tabs.details"
          contentClassName={classes.tab}
        >
          <SelectInput source="status" choices={AppLinkStatusOptions} />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const requiredValidate = [required()];

export default AppLinkEdit;
