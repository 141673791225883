import { FunctionComponent, useEffect, useState } from 'react';
import { useRecordContext, useInput } from 'react-admin';
import { get, isEmpty } from 'lodash';
import { ImageUtils } from '../utils/ImageUtils';
import { MyImageList, MyImageItem } from '../common/MyImageList';
import { ImageData } from '../entities/ImageData';

export type VideoListInputProps = {
  label?: string;
  addLabel?: boolean;
  source: string;
  dataSource: string;
};

export const VideoListInput: FunctionComponent<VideoListInputProps> = props => {
  const { source, dataSource } = props;
  const record = useRecordContext(props);
  const {
    input: { onChange },
  } = useInput(props);

  const [data, setData] = useState(
    ImageUtils.parseVideos(get(record, dataSource), get(record, source))
  );

  useEffect(() => {
    setData(
      ImageUtils.parseVideos(get(record, dataSource), get(record, source))
    );
  }, [record, source, dataSource]);

  const handleClickItem = (item: ImageData) => {
    const newData = data.map(item1 => {
      return item1 !== item
        ? item1
        : {
            ...item,
            selected: !item.selected,
          };
    });
    setData(newData);

    const indexes = newData
      .filter(item => item.selected)
      .map(item => item.youtubeId);
    onChange(indexes);
  };

  const renderItem = (item: ImageData) => {
    return (
      <MyImageItem key={item.index} data={item} onClick={handleClickItem} />
    );
  };

  return isEmpty(data) ? null : (
    <MyImageList data={data} renderItem={renderItem} />
  );
};

VideoListInput.defaultProps = {
  addLabel: true,
};
