import * as React from 'react';
import { FC } from 'react';
import {
  Edit,
  EditProps,
  FormTab,
  NumberField,
  required,
  TabbedForm,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { COLLECTION_APP_RESOURCE, COLLECTION_APP_VERSION } from '../utils';
import { JsonInput } from '../components/inputs';
import { LinkField } from '../components/fields';
import { CustomToolbar } from '../components/toolbars';

const useStyles = makeStyles({
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tab: {
    display: 'block',
  },
  fullWidth: {
    width: '100%',
  },
  dInlineBlock: {
    wordBreak: 'break-word',
    display: 'inline-block',
    width: '50%',
  },
});

const AppBuildEdit: FC<EditProps> = props => {
  const { permissions } = props;
  const canModerate = permissions?.admin || permissions?.moderator;
  const classes = useStyles();
  return (
    <Edit {...props} mutationMode={'pessimistic'}>
      <TabbedForm redirect="show" toolbar={<CustomToolbar saveable />}>
        <FormTab
          label="resources.apps.tabs.details"
          contentClassName={classes.tab}
        >
          <LinkField
            label="Resource"
            source="appId"
            reference={COLLECTION_APP_RESOURCE}
          />
          <LinkField
            label="Version"
            source="versionId"
            reference={COLLECTION_APP_VERSION}
          />
          <NumberField source="number" />
          {canModerate && (
            <>
              <JsonInput source="buildOptions" />
              <JsonInput source="runtimeOptions" />
            </>
          )}
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const requiredValidate = [required()];

export default AppBuildEdit;
