import * as React from 'react';
import { FC } from 'react';
import {
  DateField,
  TextField,
  Show,
  ShowProps,
  NumberField,
  ImageField,
  RichTextField,
  SimpleForm,
} from 'react-admin';
import { JsonField } from 'react-admin-json-view';
import { makeStyles } from '@material-ui/core/styles';
import { CustomToolbar } from '../components/toolbars';
import JsonFieldUtils from '../components/utils/JsonFieldUtils';

const useStyles = makeStyles({
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tab: {
    display: 'block',
  },
});

const CategoryShow: FC<ShowProps> = props => {
  const classes = useStyles();
  return (
    <Show {...props}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <TextField source="id" />
        <TextField source="slug" />
        <TextField source="title" />
        <ImageField source="image" />
        <RichTextField source="shortDescription" />
        <RichTextField source="description" />
        <NumberField source="priority" />
        <DateField source="updatedAt" />
        <JsonField {...JsonFieldUtils.generateProps('slugs')} />
        <JsonField {...JsonFieldUtils.generateProps('metadata')} />
      </SimpleForm>
    </Show>
  );
};

export default CategoryShow;
