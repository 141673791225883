import InvoiceIcon from '@material-ui/icons/LibraryBooks';
import TagEdit from './TagEdit';
import TagList from './TagList';
import TagShow from './TagShow';

export default {
  list: TagList,
  show: TagShow,
  // create: TagCreate,
  edit: TagEdit,
  icon: InvoiceIcon,
};
