import { FunctionComponent, useEffect, useState } from 'react';
import { useRecordContext, useInput } from 'react-admin';
import { findLastIndex, get, isEmpty } from 'lodash';
import {
  SortableContainer,
  SortableElement,
  SortEnd,
} from 'react-sortable-hoc';
import { ImageData } from '../entities/ImageData';
import { ImageUtils } from '../utils/ImageUtils';
import {
  MyImageList,
  MyImageListProps,
  MyImageItem,
  MyImageItemProps,
} from '../common/MyImageList';

const SortableImageItem = SortableElement((props: MyImageItemProps) => {
  return <MyImageItem {...props} />;
});

const SortableImageList = SortableContainer((props: MyImageListProps) => {
  return <MyImageList {...props} />;
});

export type ImageListIndexInputProps = {
  label?: string;
  addLabel?: boolean;
  source: string;
  dataSource: string;
};

export const ImageListIndexInput: FunctionComponent<
  ImageListIndexInputProps
> = props => {
  const { source, dataSource } = props;
  const record = useRecordContext(props);

  const {
    input: { onChange },
  } = useInput(props);

  const [data, setData] = useState(
    ImageUtils.parseImages(get(record, dataSource), get(record, source))
  );

  useEffect(() => {
    setData(
      ImageUtils.parseImages(get(record, dataSource), get(record, source))
    );
  }, [record, source, dataSource]);

  const updateData = (newData: ImageData[]) => {
    setData(newData);

    const selectedIndexes = newData
      .filter(item => item.selected)
      .map(item => item.index);
    onChange(selectedIndexes);
  };

  const handleClickItem = (item: ImageData) => {
    const from = data.indexOf(item);
    const to = item.selected
      ? data.length - 1
      : findLastIndex(data, item1 => item1.selected) + 1;
    item.selected = !item.selected;
    updateData(ImageUtils.reorder(data, from, to));
  };

  const handleSortEnd = (sort: SortEnd) => {
    if (!data[sort.oldIndex].selected || !data[sort.newIndex].selected) {
      // do not exchange position of 2 selected item
      return;
    }
    updateData(ImageUtils.reorder(data, sort.oldIndex, sort.newIndex));
  };

  const renderItem = (item: ImageData, index: number) => {
    return (
      <SortableImageItem data={item} index={index} onClick={handleClickItem} />
    );
  };

  return isEmpty(data) ? null : (
    <SortableImageList
      axis="xy"
      distance={10}
      data={data}
      renderItem={renderItem}
      onSortEnd={handleSortEnd}
    />
  );
};

ImageListIndexInput.defaultProps = {
  addLabel: true,
};
