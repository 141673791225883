import { request } from './request';

export async function query(): Promise<any> {
  return request('GET', '/api/users');
}

export async function queryCurrent(): Promise<any> {
  return request('GET', '/users/me');
}

export async function queryNotices(): Promise<any> {
  return request('GET', '/api/notices');
}

export async function login(data: any): Promise<any> {
  return request('POST', '/users', {
    json: data,
    body: JSON.stringify(data),
  });
}

export async function logout(): Promise<any> {
  return request('GET', '/user/logout');
}
