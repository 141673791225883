import { request } from './request';

export async function appResourcePublish(data: any): Promise<any> {
  return request('POST', `/convert/app/resources/${data.id}/publish`);
}

export async function appResourcePublishGooglePlay(data: any): Promise<any> {
  return request('POST', `/convert/app/resources/${data.id}/publish/google`);
}

export async function appResourceAudit(data: any): Promise<any> {
  return request('POST', `/convert/app/resources/${data.id}/audit`);
}

export async function appResourceUpdateImage(data: any): Promise<any> {
  return request('PATCH', `/convert/app/resources/${data.id}/image`, {
    json: data,
    body: JSON.stringify(data),
  });
}

export async function appResourceAddVideos(data: any): Promise<any> {
  return request('PATCH', `/convert/app/resources/${data.id}/videos`, {
    json: data,
    body: JSON.stringify(data),
  });
}

export async function appResourceDownloadVideos(data: any): Promise<any> {
  return request('POST', `/convert/app/resources/${data.id}/videos/download`);
}
