import * as React from 'react';
import { FC } from 'react';
import {
  Datagrid,
  DateField,
  FormTab,
  Pagination,
  ReferenceManyField,
  TabbedForm,
  TextField,
  Show,
  ShowProps,
  NumberField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {
  COLLECTION_APP_BINARY,
  COLLECTION_APP_BUILD,
  COLLECTION_APP_RESOURCE,
  COLLECTION_APP_VERSION,
} from '../utils';
import { LinkField } from '../components/fields';
import { CreateButtonWithParams } from '../components/buttons/CreateButtonWithParams';
import { CustomToolbar } from '../components/toolbars';

const useStyles = makeStyles({
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tab: {
    display: 'block',
  },
});

const AppVersionShow: FC<ShowProps> = props => {
  const { permissions } = props;
  const canModerate = permissions?.admin || permissions?.moderator;
  const classes = useStyles();
  return (
    <Show {...props}>
      <TabbedForm redirect="show" toolbar={<CustomToolbar />}>
        <FormTab
          label="resources.apps.tabs.details"
          contentClassName={classes.tab}
        >
          <TextField source="id" />
          <LinkField
            label="Resource"
            source="appId"
            reference={COLLECTION_APP_RESOURCE}
          />
          <TextField source="name" />
          <DateField source="updatedAt" />
          <CreateButtonWithParams
            reference={COLLECTION_APP_BUILD}
            params={{ id: 'versionId' }}
            label="Create App build"
          />
          <ReferenceManyField
            reference={COLLECTION_APP_BUILD}
            target="versionId"
            label="resources.app_build.name"
            perPage={5}
            pagination={<Pagination />}
            fullWidth
            sort={{ field: 'updatedAt', order: 'DESC' }}
          >
            <Datagrid rowClick="show">
              <LinkField
                label="id"
                source="id"
                reference={COLLECTION_APP_BUILD}
              />
              <LinkField
                label="Resource"
                source="appId"
                reference={COLLECTION_APP_RESOURCE}
              />
              <LinkField
                label="Version"
                source="versionId"
                reference={COLLECTION_APP_VERSION}
              />
              <NumberField source="number" />
              <DateField source="updatedAt" />
            </Datagrid>
          </ReferenceManyField>

          <ReferenceManyField
            reference={COLLECTION_APP_BINARY}
            target="versionId"
            label="resources.app_binary.name"
            perPage={5}
            pagination={<Pagination />}
            fullWidth
          >
            <Datagrid rowClick="show">
              <LinkField
                label="id"
                source="id"
                reference={COLLECTION_APP_BINARY}
              />
              <LinkField
                label="Resource"
                source="appId"
                reference={COLLECTION_APP_RESOURCE}
              />
              <LinkField
                label="Version"
                source="versionId"
                reference={COLLECTION_APP_VERSION}
              />
              <LinkField
                label="Build"
                source="buildId"
                reference={COLLECTION_APP_BUILD}
              />
              <TextField source="status" />
              <DateField source="updatedAt" />
              {/* <EditButton/> */}
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Show>
  );
};

export default AppVersionShow;
