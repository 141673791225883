import * as React from 'react';
import { FC } from 'react';
import {
  DateField,
  FormTab,
  TabbedForm,
  TextField,
  Show,
  ShowProps,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { LinkField, ImageListField } from '../components/fields';
import { COLLECTION_APP_RESOURCE } from '../utils';
import { CustomToolbar } from '../components/toolbars';

const useStyles = makeStyles({
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tab: {
    display: 'block',
  },
});

const AppScreenshotShow: FC<ShowProps> = props => {
  const classes = useStyles();
  return (
    <Show {...props}>
      <TabbedForm redirect="show" toolbar={<CustomToolbar />}>
        <FormTab
          label="resources.apps.tabs.details"
          contentClassName={classes.tab}
        >
          <TextField source="id" />
          <LinkField
            label="Resource"
            source="appId"
            reference={COLLECTION_APP_RESOURCE}
          />
          <ImageListField
            label="Images"
            source="images"
            selectedIndexesSource="selectedImageIndexes"
          />

          <TextField source="deviceDisplay" />
          <TextField source="status" />
          <DateField source="updatedAt" />
        </FormTab>
      </TabbedForm>
    </Show>
  );
};

export default AppScreenshotShow;
