import React, { FC } from 'react';
import { Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BarChartIcon from '@material-ui/icons/BarChart';
import { FilterList, FilterListItem } from 'react-admin';

import { LinkTypeOptions } from '../utils';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      width: '15em',
      marginRight: '1em',
      overflow: 'initial',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const LinkAside: FC = () => {
  // const { data, ids } = useGetList<Category>(
  //     'categories',
  //     { page: 1, perPage: 100 },
  //     { field: 'name', order: 'ASC' },
  //     {}
  // );
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardContent>
        {/* <FilterLiveSearch /> */}

        <FilterList
          label="resources.commands.fields.type"
          icon={<BarChartIcon />}
        >
          {LinkTypeOptions.map((item: any, index: number) => (
            <FilterListItem
              label={item.name}
              key={item.name}
              value={{
                type: item.id,
              }}
            />
          ))}
        </FilterList>
      </CardContent>
    </Card>
  );
};

export default LinkAside;
