import * as React from 'react';
import { FC } from 'react';
import {
  DateField,
  FormTab,
  TabbedForm,
  TextField,
  Show,
  ShowProps,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import { CustomToolbar } from '../components/toolbars';

const useStyles = makeStyles({
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tab: {
    display: 'block',
  },
  dInlineBlock: {
    wordBreak: 'break-word',
    display: 'inline-block',
    width: '50%',
  },
});

const LinkShow: FC<ShowProps> = props => {
  const classes = useStyles();
  return (
    <Show {...props}>
      <TabbedForm redirect="show" toolbar={<CustomToolbar />}>
        <FormTab
          label="resources.apps.tabs.details"
          contentClassName={classes.tab}
        >
          <TextField source="id" />
          <TextField source="source" />
          <TextField source="type" />

          <DateField source="createdAt" formClassName={classes.dInlineBlock} />
          <DateField source="updatedAt" formClassName={classes.dInlineBlock} />
        </FormTab>
      </TabbedForm>
    </Show>
  );
};

export default LinkShow;
