import { FunctionComponent, useEffect, useState } from 'react';
import { useRecordContext } from 'react-admin';
import { get, isEmpty } from 'lodash';
import { ImageUtils } from '../utils/ImageUtils';
import { MyImageList, MyImageItem } from '../common/MyImageList';
import { ImageData } from '../entities/ImageData';

export type VideoListFieldProps = {
  label?: string;
  addLabel?: boolean;
  source: string;
  dataSource: string;
};

export const VideoListField: FunctionComponent<VideoListFieldProps> = props => {
  const { source, dataSource } = props;
  const record = useRecordContext(props);
  const [data, setData] = useState(
    ImageUtils.parseVideos(get(record, dataSource), get(record, source))
  );

  useEffect(() => {
    setData(
      ImageUtils.parseVideos(get(record, dataSource), get(record, source))
    );
  }, [record, source, dataSource]);

  const renderItem = (item: ImageData) => {
    return <MyImageItem key={item.index} data={item} />;
  };

  return isEmpty(data) ? null : (
    <MyImageList data={data} renderItem={renderItem} />
  );
};

VideoListField.defaultProps = {
  addLabel: true,
};
