import * as React from 'react';
import { useEffect } from 'react';
import { Admin, Resource, DataProvider } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import { authProvider } from './authProvider';
import themeReducer from './themeReducer';
import { Layout, NewLogin } from './layout';
import { Dashboard } from './dashboard';
import customRoutes from './routes';
import englishMessages from './i18n/en';

import apps from './apps';
import appBuilds from './app-builds';
import appVersions from './app-versions';
import appBinaries from './app-binaries';
import appScreenshots from './app-screenshots';
import appResources from './app-resources';
import appLinks from './app-links';
import appProviders from './app-providers';
import categories from './categories';
import tags from './tags';
import links from './links';
import {
  COLLECTION_APP,
  COLLECTION_APP_BINARY,
  COLLECTION_APP_BUILD,
  COLLECTION_APP_LINK,
  COLLECTION_APP_PROVIDER,
  COLLECTION_APP_RESOURCE,
  COLLECTION_APP_SCREENSHOT,
  COLLECTION_APP_VERSION,
  COLLECTION_CATEGORY,
  COLLECTION_LINK,
  COLLECTION_TAG,
} from './utils';

const i18nProvider = polyglotI18nProvider(locale => {
  if (locale === 'vi') {
    return import('./i18n/vi').then(messages => messages.default);
  }

  // Always fallback on english
  return englishMessages;
}, 'en');

interface AppProps {
  onUnmount: () => void;
  dataProvider: DataProvider;
}

const App = (props: AppProps) => {
  const { onUnmount, dataProvider } = props;
  useEffect(() => {
    return onUnmount;
  }, [onUnmount]);

  return (
    <Admin
      title="Web2Mobile Admin"
      dataProvider={dataProvider}
      customReducers={{ theme: themeReducer }}
      customRoutes={customRoutes}
      authProvider={authProvider}
      dashboard={Dashboard}
      loginPage={NewLogin}
      layout={Layout}
      i18nProvider={i18nProvider}
      disableTelemetry
    >
      {pers => [
        <Resource name={COLLECTION_APP_PROVIDER} {...appProviders} />,
        <Resource name={COLLECTION_APP} {...apps} />,
        <Resource name={COLLECTION_APP_RESOURCE} {...appResources} />,
        <Resource name={COLLECTION_APP_VERSION} {...appVersions} />,
        <Resource name={COLLECTION_APP_BUILD} {...appBuilds} />,
        <Resource name={COLLECTION_APP_BINARY} {...appBinaries} />,
        <Resource name={COLLECTION_APP_SCREENSHOT} {...appScreenshots} />,
        <Resource name={COLLECTION_APP_LINK} {...appLinks} />,
        <Resource name={COLLECTION_CATEGORY} {...categories} />,
        <Resource name={COLLECTION_TAG} {...tags} />,
        <Resource name={COLLECTION_LINK} {...links} />,
      ]}
    </Admin>
  );
};

export default App;
