import * as React from 'react';
import { FC } from 'react';
import {
  Edit,
  EditProps,
  FormTab,
  NumberInput,
  ReferenceArrayInput,
  required,
  SelectArrayInput,
  TabbedForm,
  TextInput,
  BooleanInput,
  SelectInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppOrientationOptions,
  COLLECTION_CATEGORY,
  COLLECTION_TAG,
} from '../utils';
import { CustomImageInput, JsonInput } from '../components/inputs';
import { CustomToolbar } from '../components/toolbars';

const useStyles = makeStyles({
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tab: {
    display: 'block',
  },
  dInlineBlock: {
    wordBreak: 'break-word',
    display: 'inline-block',
    width: '50%',
  },
  input: {
    width: '33%',
    marginLeft: '2px',
  },
});

const AppEdit: FC<EditProps> = props => {
  const classes = useStyles();
  return (
    <Edit {...props} mutationMode={'pessimistic'}>
      <TabbedForm redirect="show" toolbar={<CustomToolbar saveable />}>
        <FormTab
          label="resources.apps.tabs.details"
          contentClassName={classes.tab}
        >
          <TextInput source="title" fullWidth validate={requiredValidate} />
          <CustomImageInput source="image" dirPath="images" />
          <TextInput fullWidth multiline source="shortDescription" />
          <TextInput
            fullWidth
            multiline
            source="description"
            validate={requiredValidate}
          />

          <ReferenceArrayInput
            source="categoryIds"
            reference={COLLECTION_CATEGORY}
          >
            <SelectArrayInput optionText="title" />
          </ReferenceArrayInput>
          <ReferenceArrayInput source="tagIds" reference={COLLECTION_TAG}>
            <SelectArrayInput optionText="title" />
          </ReferenceArrayInput>
          <NumberInput source="priority" />
          <BooleanInput source="embedEnabled" />

          <JsonInput source="metadata" />
          <>
            <SelectInput
              className={classes.input}
              source="metadata.orientation"
              choices={AppOrientationOptions}
            />
            <TextInput className={classes.input} source="metadata.owner" />
          </>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const requiredValidate = [required()];

export default AppEdit;
