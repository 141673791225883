import * as React from 'react';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { useMediaQuery, Theme, Box } from '@material-ui/core';
import {
  useTranslate,
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  usePermissions,
} from 'react-admin';

import { AppState } from '../types';
import {
  COLLECTION_APP,
  COLLECTION_APP_BINARY,
  COLLECTION_APP_BUILD,
  COLLECTION_APP_LINK,
  COLLECTION_APP_PROVIDER,
  COLLECTION_APP_RESOURCE,
  COLLECTION_APP_SCREENSHOT,
  COLLECTION_APP_VERSION,
  COLLECTION_CATEGORY,
  COLLECTION_LINK,
  COLLECTION_TAG,
} from '../utils';

type MenuName = 'menuCatalog' | 'menuSales' | 'menuCustomers';

const Menu: FC<MenuProps> = ({ onMenuClick, logout, dense = false }) => {
  const [state, setState] = useState({
    menuCatalog: true,
    menuSales: true,
    menuCustomers: true,
  });
  const translate = useTranslate();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs')
  );
  const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
  useSelector((state: AppState) => state.theme); // force rerender on theme change
  const { permissions } = usePermissions();
  const handleToggle = (menu: MenuName) => {
    setState(state => ({ ...state, [menu]: !state[menu] }));
  };
  const canModerate = permissions?.admin || permissions?.moderator;
  const canPublish =
    canModerate || permissions?.publisher || permissions?.seller;

  return (
    <Box mt={1}>
      {' '}
      <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
      {canModerate && (
        <MenuItemLink
          to={`/${COLLECTION_CATEGORY}`}
          primaryText={translate(`resources.categories.name`, {
            smart_count: 2,
          })}
          leftIcon={<MenuBookIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}
      {canModerate && (
        <MenuItemLink
          to={`/${COLLECTION_TAG}`}
          primaryText={translate(`resources.tags.name`, {
            smart_count: 2,
          })}
          leftIcon={<MenuBookIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}
      {canModerate && (
        <MenuItemLink
          to={`/${COLLECTION_LINK}`}
          primaryText={translate(`resources.links.name`, {
            smart_count: 2,
          })}
          leftIcon={<MenuBookIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}
      {canModerate && (
        <MenuItemLink
          to={`/${COLLECTION_APP_PROVIDER}`}
          primaryText={translate(`resources.app_provider.name`, {
            smart_count: 2,
          })}
          leftIcon={<MenuBookIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}
      {canModerate && (
        <MenuItemLink
          to={`/${COLLECTION_APP}`}
          primaryText={translate(`resources.apps.name`, {
            smart_count: 2,
          })}
          leftIcon={<MenuBookIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}
      <MenuItemLink
        to={`/${COLLECTION_APP_RESOURCE}`}
        primaryText={translate(`resources.app_resource.name`, {
          smart_count: 2,
        })}
        leftIcon={<MenuBookIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      {canPublish && (
        <MenuItemLink
          to={`/${COLLECTION_APP_VERSION}`}
          primaryText={translate(`resources.app_version.name`, {
            smart_count: 2,
          })}
          leftIcon={<MenuBookIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}
      {canPublish && (
        <MenuItemLink
          to={`/${COLLECTION_APP_BUILD}`}
          primaryText={translate(`resources.app_build.name`, {
            smart_count: 2,
          })}
          leftIcon={<MenuBookIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}
      {canPublish && (
        <MenuItemLink
          to={`/${COLLECTION_APP_BINARY}`}
          primaryText={translate(`resources.app_binary.name`, {
            smart_count: 2,
          })}
          leftIcon={<MenuBookIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}
      {canPublish && (
        <MenuItemLink
          to={`/${COLLECTION_APP_SCREENSHOT}`}
          primaryText={translate(`resources.app_screenshot.name`, {
            smart_count: 2,
          })}
          leftIcon={<MenuBookIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}
      {canModerate && (
        <MenuItemLink
          to={`/${COLLECTION_APP_LINK}`}
          primaryText={translate(`resources.app_links.name`, {
            smart_count: 2,
          })}
          leftIcon={<MenuBookIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}
      {isXSmall && (
        <MenuItemLink
          to="/configuration"
          primaryText={translate('pos.configuration')}
          leftIcon={<SettingsIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}
      {isXSmall && logout}
    </Box>
  );
};

export default Menu;
