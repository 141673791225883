import * as React from 'react';
import { FC } from 'react';
import {
  Create,
  CreateProps,
  FormTab,
  NumberInput,
  ReferenceArrayInput,
  required,
  SelectArrayInput,
  TabbedForm,
  TextInput,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { makeStyles } from '@material-ui/core/styles';
import { COLLECTION_CATEGORY, COLLECTION_TAG } from '../utils';
import { CustomImageInput, JsonInput } from '../components/inputs';
import { CustomToolbar } from '../components/toolbars';

export const styles = {
  price: { width: '7em' },
  width: { width: '7em' },
  height: { width: '7em' },
  stock: { width: '7em' },
  widthFormGroup: { display: 'inline-block' },
  heightFormGroup: { display: 'inline-block', marginLeft: 32 },
};
const useStyles = makeStyles(styles);

const AppCreate: FC<CreateProps> = props => {
  const classes = useStyles();
  return (
    <Create {...props}>
      <TabbedForm
        redirect="show"
        toolbar={<CustomToolbar saveable alwaysEnableSaveButton={true} />}
      >
        <FormTab label="resources.apps.tabs.details">
          <NumberInput source="id" />
          <TextInput source="title" fullWidth validate={requiredValidate} />
          <CustomImageInput source="image" dirPath="images" />
          <RichTextInput source="shortDescription" fullWidth />
          <RichTextInput
            source="description"
            label="Description"
            validate={requiredValidate}
          />

          <ReferenceArrayInput
            source="categoryIds"
            reference={COLLECTION_CATEGORY}
          >
            <SelectArrayInput optionText="title" />
          </ReferenceArrayInput>
          <ReferenceArrayInput source="tagIds" reference={COLLECTION_TAG}>
            <SelectArrayInput optionText="title" />
          </ReferenceArrayInput>
          <NumberInput source="priority" />
          <TextInput source="iosBundleId" />
          <TextInput source="androidApplicationId" />

          <JsonInput source="metadata" />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};
const requiredValidate = [required()];

export default AppCreate;
