import InvoiceIcon from '@material-ui/icons/LibraryBooks';
import CategoryEdit from './CategoryEdit';
import CategoryList from './CategoryList';
import CategoryShow from './CategoryShow';

export default {
  list: CategoryList,
  show: CategoryShow,
  // create: CategoryCreate,
  edit: CategoryEdit,
  icon: InvoiceIcon,
};
