import InvoiceIcon from '@material-ui/icons/LibraryBooks';
import AppVersionCreate from './AppVersionCreate';
import AppVersionList from './AppVersionList';
import AppVersionShow from './AppVersionShow';

export default {
  list: AppVersionList,
  show: AppVersionShow,
  create: AppVersionCreate,
  icon: InvoiceIcon,
};
