import { FunctionComponent, ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { ImageData } from '../entities/ImageData';
import { isEmpty } from 'lodash';

const useStyles = makeStyles(
  {
    imageList: {
      display: 'inline-flex',
      flexWrap: 'wrap',
    },
    imageItem: {
      height: 'auto',
      width: '30%',
      position: 'relative',
      paddingRight: 10,
      paddingBottom: 10,
    },
    image: {
      width: '100%',
      height: 'auto',
    },
    checkedIcon: {
      height: 32,
      width: 32,
      color: '#6ff51e',
      position: 'absolute',
      top: 4,
      left: 4,
    },
    playCircleIcon: {
      width: 44,
      height: 44,
      color: '#ff0100',
      position: 'absolute',
      top: 4,
      right: 14,
    },
  },
  { name: 'RaImageList' }
);

export type MyImageItemProps = {
  data: ImageData;
  onClick?: (item: ImageData) => void;
};

export const MyImageItem: FunctionComponent<MyImageItemProps> = props => {
  const { data, onClick } = props;

  const classes = useStyles(props);

  const handleClick = () => {
    onClick && onClick(data);
  };

  return (
    <div className={classes.imageItem} onClick={handleClick}>
      <img
        alt={data.src}
        title={data.src}
        src={data.src}
        className={classes.image}
      />
      {data.selected && <CheckCircleIcon className={classes.checkedIcon} />}
      {!isEmpty(data.youtubeId) && (
        <a
          href={`https://www.youtube.com/watch?v=${data.youtubeId}`}
          target="_blank"
          rel="noreferrer"
          onClick={e => e.stopPropagation()}
        >
          <PlayCircleFilledIcon className={classes.playCircleIcon} />
        </a>
      )}
    </div>
  );
};

export type MyImageListProps = {
  data: ImageData[];
  renderItem: (item: ImageData, index: number) => ReactElement;
};

export const MyImageList: FunctionComponent<MyImageListProps> = props => {
  const { data, renderItem } = props;

  const classes = useStyles(props);

  return <div className={classes.imageList}>{data.map(renderItem)}</div>;
};
