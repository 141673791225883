import { FunctionComponent, useEffect, useState } from 'react';
import { useRecordContext, useInput } from 'react-admin';
import { get, isEmpty } from 'lodash';
import {
  SortableContainer,
  SortableElement,
  SortEnd,
} from 'react-sortable-hoc';
import { ImageData } from '../entities/ImageData';
import { ImageUtils } from '../utils/ImageUtils';
import {
  MyImageList,
  MyImageListProps,
  MyImageItem,
  MyImageItemProps,
} from '../common/MyImageList';

const SortableImageItem = SortableElement((props: MyImageItemProps) => {
  return <MyImageItem {...props} />;
});

const SortableImageList = SortableContainer((props: MyImageListProps) => {
  return <MyImageList {...props} />;
});

export type ImageListInputProps = {
  label?: string;
  addLabel?: boolean;
  source: string;
};

export const ImageListInput: FunctionComponent<ImageListInputProps> = props => {
  const { source } = props;
  const record = useRecordContext(props);

  const {
    input: { onChange },
  } = useInput(props);

  const [data, setData] = useState(ImageUtils.parseImages(get(record, source)));

  useEffect(() => {
    setData(ImageUtils.parseImages(get(record, source)));
  }, [record, source]);

  const updateData = (newData: ImageData[]) => {
    setData(newData);

    const srcs = newData.map(item => item.src);
    onChange(srcs);
  };

  const handleSortEnd = (sort: SortEnd) => {
    updateData(ImageUtils.reorder(data, sort.oldIndex, sort.newIndex));
  };

  const renderItem = (item: ImageData, index: number) => {
    return <SortableImageItem data={item} index={index} />;
  };

  return isEmpty(data) ? null : (
    <SortableImageList
      axis="xy"
      distance={10}
      data={data}
      renderItem={renderItem}
      onSortEnd={handleSortEnd}
    />
  );
};

ImageListInput.defaultProps = {
  addLabel: true,
};
