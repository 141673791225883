import * as React from 'react';
import { FC } from 'react';
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  DateField,
  EditButton,
} from 'react-admin';
import {
  COLLECTION_APP,
  COLLECTION_APP_RESOURCE,
  COLLECTION_LINK,
} from '../utils';
import { LinkField } from '../components/fields';
import AppLinkAside from './AppLinkAside';

const AppLinkList: FC<ListProps> = props => {
  console.log('applist: ', props);
  return (
    <List
      {...props}
      perPage={25}
      sort={{ field: 'updatedAt', order: 'DESC' }}
      bulkActionButtons={false}
      aside={<AppLinkAside />}
    >
      <Datagrid optimized rowClick="show">
        <TextField source="id" />
        <LinkField label="App" source="appId" reference={COLLECTION_APP} />
        <LinkField
          label="Resource"
          source="appId"
          reference={COLLECTION_APP_RESOURCE}
        />
        <LinkField
          label="Link id"
          source="linkId"
          reference={COLLECTION_LINK}
        />
        <TextField source="status" />
        <DateField source="updatedAt" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default AppLinkList;
