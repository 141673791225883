import InvoiceIcon from '@material-ui/icons/LibraryBooks';
import LinkCreate from './LinkCreate';
import LinkEdit from './LinkEdit';
import LinkList from './LinkList';
import LinkShow from './LinkShow';

export default {
  list: LinkList,
  show: LinkShow,
  create: LinkCreate,
  edit: LinkEdit,
  icon: InvoiceIcon,
};
