import { FunctionComponent, useEffect, useState } from 'react';
import { useRecordContext } from 'react-admin';
import { get, isEmpty } from 'lodash';
import { ImageUtils } from '../utils/ImageUtils';
import { MyImageList, MyImageItem } from '../common/MyImageList';
import { ImageData } from '../entities/ImageData';

export type ImageListFieldProps = {
  label?: string;
  addLabel?: boolean;
  source: string;
  selectedIndexesSource?: string;
};

export const ImageListField: FunctionComponent<ImageListFieldProps> = props => {
  const { source, selectedIndexesSource } = props;
  const record = useRecordContext(props);
  const [data, setData] = useState(
    ImageUtils.parseImages(
      get(record, source),
      selectedIndexesSource ? get(record, selectedIndexesSource) : []
    )
  );

  useEffect(() => {
    setData(
      ImageUtils.parseImages(
        get(record, source),
        selectedIndexesSource ? get(record, selectedIndexesSource) : []
      )
    );
  }, [record, source, selectedIndexesSource]);

  const renderItem = (item: ImageData) => {
    return <MyImageItem key={item.src} data={item} />;
  };

  return isEmpty(data) ? null : (
    <MyImageList data={data} renderItem={renderItem} />
  );
};

ImageListField.defaultProps = {
  addLabel: true,
};
