import InvoiceIcon from '@material-ui/icons/LibraryBooks';
import AppScreenshotEdit from './AppScreenshotEdit';
import AppScreenshotList from './AppScreenshotList';
import AppScreenshotShow from './AppScreenshotShow';

export default {
  list: AppScreenshotList,
  show: AppScreenshotShow,
  // create: AppScreenshotCreate,
  edit: AppScreenshotEdit,
  icon: InvoiceIcon,
};
