import * as React from 'react';
import { FC } from 'react';
import {
  Datagrid,
  DateField,
  EditButton,
  FormTab,
  Pagination,
  ReferenceManyField,
  TabbedForm,
  TextField,
  Show,
  ShowProps,
  NumberField,
  ImageField,
  RichTextField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  BooleanField,
} from 'react-admin';
import { JsonField } from 'react-admin-json-view';
import { makeStyles } from '@material-ui/core/styles';
import {
  COLLECTION_APP_PROVIDER,
  COLLECTION_APP_RESOURCE,
  COLLECTION_CATEGORY,
  COLLECTION_TAG,
} from '../utils';
import { LinkField } from '../components/fields';
import { CustomToolbar } from '../components/toolbars';
import JsonFieldUtils from '../components/utils/JsonFieldUtils';

const useStyles = makeStyles({
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tab: {
    display: 'block',
  },
  dInlineBlock: {
    wordBreak: 'break-word',
    display: 'inline-block',
    width: '50%',
  },
});

const AppShow: FC<ShowProps> = props => {
  const classes = useStyles();
  return (
    <Show {...props}>
      <TabbedForm redirect="show" toolbar={<CustomToolbar />}>
        <FormTab
          label="resources.apps.tabs.details"
          contentClassName={classes.tab}
        >
          <ReferenceManyField
            reference={COLLECTION_APP_PROVIDER}
            target="appId"
            label="resources.app_provider.name"
            perPage={5}
            pagination={<Pagination />}
            fullWidth
            sort={{ field: 'updatedAt', order: 'DESC' }}
          >
            <Datagrid rowClick="show">
              <LinkField
                label="id"
                source="id"
                reference={COLLECTION_APP_PROVIDER}
              />
              <TextField label="Provider" source="providerSlug" />
              <TextField source="title" />
              <NumberField source="priority" />
              <DateField source="updatedAt" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>

          <TextField source="id" />
          <LinkField
            formClassName={classes.dInlineBlock}
            label="App resource"
            source="id"
            reference={COLLECTION_APP_RESOURCE}
          />

          <TextField source="title" />
          <ImageField source="image" />
          <RichTextField source="shortDescription" />
          <RichTextField source="description" />
          <ReferenceArrayField
            label="categoryIds"
            reference={COLLECTION_CATEGORY}
            source="categoryIds"
            fullWidth
          >
            <SingleFieldList>
              <ChipField source="title" />
            </SingleFieldList>
          </ReferenceArrayField>
          <ReferenceArrayField
            label="tagIds"
            reference={COLLECTION_TAG}
            source="tagIds"
            fullWidth
          >
            <SingleFieldList>
              <ChipField source="title" />
            </SingleFieldList>
          </ReferenceArrayField>
          <NumberField source="priority" />
          <BooleanField source="embedEnabled" />
          <JsonField {...JsonFieldUtils.generateProps('metadata')} />
          <DateField source="createdAt" formClassName={classes.dInlineBlock} />
          <DateField source="updatedAt" formClassName={classes.dInlineBlock} />
        </FormTab>
      </TabbedForm>
    </Show>
  );
};

export default AppShow;
