import InvoiceIcon from '@material-ui/icons/LibraryBooks';
import AppBinaryCreate from './AppBinaryCreate';
import AppBinaryEdit from './AppBinaryEdit';
import AppBinaryList from './AppBinaryList';
import AppBinaryShow from './AppBinaryShow';

export default {
  list: AppBinaryList,
  show: AppBinaryShow,
  create: AppBinaryCreate,
  edit: AppBinaryEdit,
  icon: InvoiceIcon,
};
