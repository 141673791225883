import * as React from 'react';
import { FC } from 'react';
import {
  DateField,
  FormTab,
  TabbedForm,
  TextField,
  Show,
  ShowProps,
  NumberField,
  ImageField,
  RichTextField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
} from 'react-admin';
import { JsonField } from 'react-admin-json-view';
import { makeStyles } from '@material-ui/core/styles';
import {
  COLLECTION_APP,
  COLLECTION_APP_RESOURCE,
  COLLECTION_CATEGORY,
  COLLECTION_TAG,
} from '../utils';
import { LinkField } from '../components/fields';
import { CustomToolbar } from '../components/toolbars';
import JsonFieldUtils from '../components/utils/JsonFieldUtils';

const useStyles = makeStyles({
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tab: {
    display: 'block',
  },
  dInlineBlock: {
    wordBreak: 'break-word',
    display: 'inline-block',
    width: '50%',
  },
});

const AppProviderShow: FC<ShowProps> = props => {
  const classes = useStyles();
  return (
    <Show {...props}>
      <TabbedForm redirect="show" toolbar={<CustomToolbar />}>
        <FormTab
          label="resources.apps.tabs.details"
          contentClassName={classes.tab}
        >
          <TextField source="id" />
          <LinkField label="App" source="appId" reference={COLLECTION_APP} />
          <LinkField
            label="Resource"
            source="appId"
            reference={COLLECTION_APP_RESOURCE}
          />
          <TextField source="providerName" />
          <TextField source="title" />
          <ImageField source="image" />
          <RichTextField source="description" />
          <ReferenceArrayField
            label="categoryIds"
            reference={COLLECTION_CATEGORY}
            source="categoryIds"
            fullWidth
          >
            <SingleFieldList>
              <ChipField source="title" />
            </SingleFieldList>
          </ReferenceArrayField>
          <ReferenceArrayField
            label="tagIds"
            reference={COLLECTION_TAG}
            source="tagIds"
            fullWidth
          >
            <SingleFieldList>
              <ChipField source="title" />
            </SingleFieldList>
          </ReferenceArrayField>
          <NumberField source="priority" />
          <JsonField {...JsonFieldUtils.generateProps('metadata')} />
          <JsonField {...JsonFieldUtils.generateProps('providerMetadata')} />

          <DateField source="createdAt" formClassName={classes.dInlineBlock} />
          <DateField source="updatedAt" formClassName={classes.dInlineBlock} />
        </FormTab>
      </TabbedForm>
    </Show>
  );
};

export default AppProviderShow;
