import * as React from 'react';
import { FC } from 'react';
import {
  DateField,
  Edit,
  EditProps,
  FormTab,
  required,
  SelectInput,
  TabbedForm,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {
  COLLECTION_APP_RESOURCE,
  DeviceDisplayOptions,
  ScreenshotStatusOptions,
} from '../utils';
import { ImageListIndexInput } from '../components/inputs';
import { CustomToolbar } from '../components/toolbars';
import { LinkField } from '../components/fields';

const useStyles = makeStyles({
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tab: {
    display: 'block',
  },
});

const AppScreenshotEdit: FC<EditProps> = props => {
  const classes = useStyles();
  return (
    <Edit {...props} mutationMode={'pessimistic'}>
      <TabbedForm redirect="show" toolbar={<CustomToolbar saveable />}>
        <FormTab
          label="resources.apps.tabs.details"
          contentClassName={classes.tab}
        >
          <LinkField
            label="Resource"
            source="appId"
            reference={COLLECTION_APP_RESOURCE}
          />
          <ImageListIndexInput
            source="selectedImageIndexes"
            dataSource="images"
            label="Images"
          />
          <SelectInput source="deviceDisplay" choices={DeviceDisplayOptions} />
          <SelectInput source="status" choices={ScreenshotStatusOptions} />
          <DateField source="updatedAt" />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const requiredValidate = [required()];

export default AppScreenshotEdit;
