import { httpClient } from '../dataProvider/rest';

export const request = async (
  method: string,
  url: string,
  params: any = {}
) => {
  try {
    return await httpClient(`${process.env.REACT_APP_API_URL}${url}`, {
      method,
      ...params,
    });
  } catch (error: any) {
    return {
      status: error.statusCode,
      ...error,
    };
  }
};
