import * as React from 'react';
import { FC } from 'react';
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  DateField,
  Filter,
  TextInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { COLLECTION_APP_RESOURCE, COLLECTION_APP_VERSION } from '../utils';
import { LinkField } from '../components/fields';

const useStyles = makeStyles(theme => ({
  hiddenOnSmallScreens: {
    display: 'table-cell',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

const VersionFilter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput label="Id" source="id_eq" defaultValue="" alwaysOn />
      <TextInput label="Resource" source="appId_eq" defaultValue="" alwaysOn />
    </Filter>
  );
};

const AppVersionList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      perPage={25}
      sort={{ field: 'updatedAt', order: 'DESC' }}
      bulkActionButtons={false}
      filters={<VersionFilter />}
    >
      <Datagrid optimized rowClick="show">
        <LinkField label="id" source="id" reference={COLLECTION_APP_VERSION} />
        <LinkField
          label="Resource"
          source="appId"
          reference={COLLECTION_APP_RESOURCE}
        />
        <TextField source="name" />
        <DateField source="updatedAt" />
      </Datagrid>
    </List>
  );
};

export default AppVersionList;
