import InvoiceIcon from '@material-ui/icons/LibraryBooks';
import AppCreate from './AppCreate';
import AppEdit from './AppEdit';
import AppList from './AppList';
import AppShow from './AppShow';

export default {
  list: AppList,
  show: AppShow,
  create: AppCreate,
  edit: AppEdit,
  icon: InvoiceIcon,
};
