import * as React from 'react';
import { FC } from 'react';
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  Filter,
  EditButton,
  TextInput,
} from 'react-admin';
import { lowerCase } from 'lodash';
import { COLLECTION_APP } from '../utils';
import { LinkField } from '../components/fields';

const AppFilter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput label="Id" source="id_eq" defaultValue="" alwaysOn />
      <TextInput
        label="Title"
        source="title_contL"
        defaultValue=""
        alwaysOn
        parse={(value: string) => lowerCase(value)}
      />
    </Filter>
  );
};

const AppList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      perPage={25}
      sort={{ field: 'updatedAt', order: 'DESC' }}
      bulkActionButtons={false}
      filters={<AppFilter />}
    >
      <Datagrid optimized rowClick="show">
        <LinkField label="id" source="id" reference={COLLECTION_APP} />
        <TextField source="title" />
        <NumberField source="priority" />
        <DateField source="updatedAt" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default AppList;
