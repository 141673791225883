import React from 'react';
import { InputProps } from 'react-admin';
import { JsonInput as OriginalJsonInput } from 'react-admin-json-view';

export const JsonInput = (
  props: JSX.IntrinsicAttributes & {
    source: string;
  } & InputProps<any> & { children?: React.ReactNode }
) => {
  return (
    <OriginalJsonInput
      reactJsonOptions={{
        name: null,
        collapsed: true,
        enableClipboard: true,
        displayDataTypes: true,
      }}
      jsonString={false}
      {...props}
    />
  );
};
