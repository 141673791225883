import { FieldProps } from 'react-admin';
import { ReactJsonViewProps } from 'react-json-view';

class JsonFieldUtils {
  static generateProps = (
    source: string
  ): {
    source: string;
    record?: any;
    jsonString?: boolean;
    reactJsonOptions?: Omit<ReactJsonViewProps, 'src'>;
  } & FieldProps => {
    return {
      source,
      addLabel: true,
      reactJsonOptions: {
        name: null,
        collapsed: true,
        enableClipboard: true,
        displayDataTypes: true,
      },
      jsonString: false,
    };
  };
}

export default JsonFieldUtils;
