import { request } from './request';

export async function upload(dir: string, file: File): Promise<any> {
  const formData = new FormData();
  formData.append('file', file, file.name);
  formData.append('directory', dir);
  const params = {
    body: formData,
    redirect: 'follow',
  };
  return request('POST', `/files`, params);
}
