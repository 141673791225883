import * as React from 'react';
import { FC } from 'react';
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  Filter,
  EditButton,
  TextInput,
  SelectInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { lowerCase } from 'lodash';
import { LinkField } from '../components/fields';
import {
  AgentQueueNameOptions,
  COLLECTION_APP,
  COLLECTION_APP_RESOURCE,
  ResourceStatusOptions,
} from '../utils';

const useStyles = makeStyles(theme => ({
  hiddenOnSmallScreens: {
    display: 'table-cell',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

const AppResourceFilter = (props: any) => {
  const { canModerate } = props;
  return (
    <Filter {...props}>
      <TextInput label="Id" source="id_eq" defaultValue="" alwaysOn />
      <TextInput
        label="Title"
        source="title_contL"
        defaultValue=""
        alwaysOn
        parse={(value: string) => lowerCase(value)}
      />
      {canModerate && (
        <TextInput
          label="Account"
          source="accountId_eq"
          defaultValue=""
          alwaysOn
        />
      )}
      {canModerate && (
        <SelectInput
          label="Queue"
          source="queueName_eq"
          choices={AgentQueueNameOptions}
          alwaysOn
        />
      )}
      {canModerate && (
        <TextInput
          label="Amazon Account"
          source="amazonAccountId_eq"
          defaultValue=""
          alwaysOn
        />
      )}
      {canModerate && (
        <SelectInput
          label="Amazon Queue"
          source="amazonQueueName_eq"
          choices={AgentQueueNameOptions}
          alwaysOn
        />
      )}
      <SelectInput
        label="Status"
        source="status_eq"
        choices={ResourceStatusOptions}
        alwaysOn
      />
    </Filter>
  );
};

const AppResourceList: FC<ListProps> = props => {
  const { permissions } = props;
  const canModerate = permissions?.admin || permissions?.moderator;
  return (
    <List
      {...props}
      perPage={25}
      sort={{ field: 'priority', order: 'DESC' }}
      bulkActionButtons={false}
      filters={<AppResourceFilter canModerate={canModerate} />}
    >
      <Datagrid optimized rowClick="show">
        <LinkField label="id" source="id" reference={COLLECTION_APP_RESOURCE} />
        <LinkField label="App" source="id" reference={COLLECTION_APP} />
        {canModerate && <NumberField source="accountId" />}
        {canModerate && <TextField source="queueName" />}
        {canModerate && <NumberField source="amazonAccountId" />}
        {canModerate && <TextField source="amazonQueueName" />}
        <TextField source="title" />
        <NumberField source="priority" />
        <TextField source="status" />
        <DateField source="updatedAt" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default AppResourceList;
