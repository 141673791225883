import * as React from 'react';
import { FC } from 'react';
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  Filter,
  TextInput,
  SelectInput,
} from 'react-admin';
import { lowerCase } from 'lodash';
import {
  AppProviderSlugOptions,
  COLLECTION_APP,
  COLLECTION_APP_PROVIDER,
  COLLECTION_APP_RESOURCE,
} from '../utils';
import { LinkField } from '../components/fields';

const AppProviderFilter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput label="Id" source="id_eq" defaultValue="" alwaysOn />
      <TextInput
        label="Title"
        source="title_contL"
        defaultValue=""
        alwaysOn
        parse={(value: string) => lowerCase(value)}
      />
      <SelectInput
        label="Provider"
        source="providerSlug_eq"
        choices={[...AppProviderSlugOptions]}
        alwaysOn
      />
    </Filter>
  );
};

const AppProviderList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      perPage={25}
      sort={{ field: 'priority', order: 'DESC' }}
      bulkActionButtons={false}
      filters={<AppProviderFilter />}
    >
      <Datagrid optimized rowClick="show">
        <LinkField label="id" source="id" reference={COLLECTION_APP_PROVIDER} />
        <LinkField label="App" source="appId" reference={COLLECTION_APP} />
        <LinkField
          label="Resource"
          source="appId"
          reference={COLLECTION_APP_RESOURCE}
        />
        <TextField label="Provider" source="providerSlug" />
        <TextField source="title" />
        <NumberField source="priority" />
        <DateField source="updatedAt" />
        {/* <EditButton /> */}
      </Datagrid>
    </List>
  );
};

export default AppProviderList;
