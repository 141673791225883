import * as React from 'react';
import { FC } from 'react';
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  EditButton,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { LinkField } from '../components/fields';
import { COLLECTION_TAG } from '../utils';

const useStyles = makeStyles(theme => ({
  hiddenOnSmallScreens: {
    display: 'table-cell',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

const TagList: FC<ListProps> = props => {
  const classes = useStyles();
  return (
    <List {...props} perPage={25} sort={{ field: 'priority', order: 'DESC' }}>
      <Datagrid optimized rowClick="show">
        <LinkField label="id" source="id" reference={COLLECTION_TAG} />
        <TextField source="slug" />
        <TextField source="title" />
        <NumberField source="priority" />
        <DateField source="updatedAt" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default TagList;
